'use client';
import React, { useState } from 'react';
import {
    AppLayout,
    ContentLayout,
    SideNavigation,
} from '@cloudscape-design/components';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.en';
import Button from "@amzn/awsui-components-react/polaris/button";
import fetch from "@amzn/sentry-fetch";
import Spinner from "@cloudscape-design/components/spinner";


const LOCALE = 'en';

export default function Home() {

    const [ response, setResponse ] = useState<any>(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const handleHealthCheck = async () => {
        setIsLoading(true);
        try {
            const url = "https://beta.febtool.kindle.amazon.dev/healthCheck";
            const response = await fetch(url, {
                method: 'GET',
                headers: {'Content-Type': 'application/json'},
                credentials: "include"
            });
            if (response.status === 200) {
                const data = await response.json();
                setResponse(data); // Store the response in state
                console.log("Health Check Successful");
                console.log(data);
            } else {
                console.log("Failed to Decline. Please try again.");
            }
        } catch (error) {
            console.error("Error:", error);
            console.log("An error occurred. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <I18nProvider locale={LOCALE} messages={[ messages ]}>

            <br></br>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh'}}>
                <div style={{textAlign: 'center'}}>
                    <h1>Welcome to the Former Employee Bindle Clean Up Tool</h1>
                    <p>This tool is used to clean up the former employee bindle.</p>
                </div>
            </div>

            <Button
                variant="primary"
                onClick={handleHealthCheck}
            >Health Check Button</Button>

            <div className="response-container">
                {isLoading ? (
                    <span> <Spinner size="large" /></span>
                ) : (
                    response && <pre>{JSON.stringify(response, null, 2)}</pre>
                )}
            </div>

            <AppLayout
                navigationHide={true}
                toolsHide={true}
                navigation={
                    <SideNavigation
                        header={{
                            href: '/',
                            text: 'Former Employee Resource Management',
                        }}
                        items={[
                            {type: 'link', text: 'Home', href: '/'},
                            {type: 'link', text: 'Resource Summary', href: '/resources-summary'},
                            {type: 'link', text: 'Resource Management', href: '/resources'},
                            {
                                type: "link",
                                text: "Documentation",
                                href: "https://example.com",
                                external: true
                            }
                        ]}
                    />
                }
                content={
                    <ContentLayout>
                    </ContentLayout>
                }
            />
        </I18nProvider>
    );
}
