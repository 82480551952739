
import { Header, Link} from "@cloudscape-design/components";
import React from "react";

export default function PermissionHandler() {
    return (
            <Header variant="h1" info={<Link variant="info">Info</Link>}>
                Permission Handler
            </Header>
    );
}