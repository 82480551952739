'use client';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
    AppLayout,
    Container,
    ContentLayout,
    SideNavigation,
} from '@amzn/awsui-components-react/polaris';
import ResourceManagement from "./pages/ResourceManagement";
import Home from "./pages/Home";
import DeletionHandler from "./pages/DeletionHandler";
import PermissionHandler from "./pages/PermissionHandler";


export default function AppLayoutPreview() {
    return (
        <Router>
                <AppLayout
                    navigation={
                        <SideNavigation
                            header={{
                                href: '/',
                                text: 'Former Employee Resource Management',
                            }}
                            items={[
                                { type: 'link', text: 'Home', href: '/' },
                                { type: 'link', text: 'Resource Management', href: '/resourcesManagement' },
                                { type: 'link', text: 'Permission Management', href: '/permissionsHandler' },
                                { type: 'link', text: 'Deletion Handler', href: '/deletionHandler' },
                                {
                                    type: "link",
                                    text: "Documentation",
                                    href: "https://example.com",
                                    external: true
                                }
                            ]}
                        />
                    }
                    content={
                        <ContentLayout>
                            <Container>
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/resourcesManagement" element={<ResourceManagement />} />
                                    <Route path="/permissionsHandler" element={<PermissionHandler />} />
                                    <Route path="/deletionHandler" element={<DeletionHandler />} />
                                </Routes>
                            </Container>
                        </ContentLayout>
                    }
                />
        </Router>
    );
}
